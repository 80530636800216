import React from "react";
import styled from "styled-components";
import WorkDisplayImage from "./WorkDisplayImage";
import WorkDisplayInfo from "./WorkDisplayInfo";

import { device } from "../../../breakpoints";

type WorkDisplayProps = {
  imagesource: string;
  imageAlt: string;
  linkToProject: string;
  linkToCode?: string;
  projectName: string;
  projectType: string;
  flipColumnPosition?: boolean;
};

const StyledWorkDisplay = styled.div`
  display: grid;
  position: relative;
  grid-template: 1fr / 1fr 1fr;
  align-items: center;
  justify-items: center;

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    grid-area: unset;
  }

  @media ${device.tabletS} {
    grid-template: 1fr auto / 1fr;
    margin: 0;
    background-color: var(--neutral--color-800);
    border-radius: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23616e7c' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
  }
`;

const StyledCardBackground = styled.div`
  position: absolute;
  border-radius: 5px;
  background-color: var(--neutral--color-800);
  width: 100%;
  height: 90%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23616e7c' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");

  @media ${device.tabletS} {
    display: none;
    grid-template: 1fr auto / 1fr;
    margin: 48px 0 48px 0;
    width: 100%;
    max-width: 480px;
    height: 100%;
  }
`;

const WorkDisplay = ({
  imagesource,
  imageAlt,
  linkToProject,
  linkToCode,
  projectName,
  projectType,
  flipColumnPosition,
}: WorkDisplayProps): JSX.Element => {
  if (flipColumnPosition) {
    return (
      <StyledWorkDisplay>
        <StyledCardBackground />
        <WorkDisplayInfo
          linkToProject={linkToProject}
          linkToCode={linkToCode}
          projectName={projectName}
          projectType={projectType}
        />
        <WorkDisplayImage src={imagesource} alt={imageAlt} />
      </StyledWorkDisplay>
    );
  }

  return (
    <StyledWorkDisplay>
      <StyledCardBackground />
      <WorkDisplayImage src={imagesource} alt={imageAlt} />
      <WorkDisplayInfo
        linkToProject={linkToProject}
        linkToCode={linkToCode}
        projectName={projectName}
        projectType={projectType}
      />
    </StyledWorkDisplay>
  );
};

export default WorkDisplay;
